import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Send from '@mui/icons-material/Send';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { jwtDecode } from 'jwt-decode';
import * as chatService from '../services/chatService';
import { AuthContext } from '../context/authContext';
import Footer from '../components/Footer';
import { VisuallyHiddenInput } from './Upload';
import { Paper } from '@mui/material';
import imageCompression from 'browser-image-compression';
import * as paymentService from '../services/paymentService';
import { useStripe } from '@stripe/react-stripe-js';
import { CircularIndeterminate } from './AboutPage'
import { Snackbar, Alert } from '@mui/material';

let userId = undefined;
let fullName = undefined;
let token = undefined;
let h3Width = undefined;
let h3Height = undefined;
let maxFreeWords = 3000;
let imageLimitPerPayment = 10;
let isScrolled = true;
const ChatPage = () => {
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isCreatingChat, setIsCreatingChat] = useState(false);
    const [newChatName, setNewChatName] = useState('');
    const [chats, setChats] = useState([]);
    const [currentChat, setCurrentChat] = useState([]);
    const [editingChat, setEditingChat] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [hideImage, setHideImage] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const [userDetails, setUserDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [assistantResponseShown, setAssistantResponseShown] = useState(false);
    const [timerActive, setTimerActive] = useState(true);
    const [blinkingActive, setBlinkingActive] = useState(false);
    const [errorApi, setErrorApi] = useState('');

    const fileInputRef = useRef(null);
    const imageRef = useRef(null);
    const h3Ref = useRef(null);
    const textFieldRef = useRef(null);
    const { authState, signout } = useContext(AuthContext);
    const navigate = useNavigate();

    const stripe = useStripe();

    if (authState.accessToken) {
        const decode = jwtDecode(authState.accessToken);
        userId = decode.id;
        fullName = decode.firstName + ' ' + decode.lastName;
        token = authState.accessToken;
    }
    useEffect(() => {
        if (userId && token) {
            chatService
                .getChatsNamesByUserId(userId, token)
                .then((data) => {
                    setChats(data);
                })
                .catch((error) => {
                    if (error.message === 'Unauthorized') {
                        console.log(error.message);
                        logout()
                    }
                    console.log(error.message);
                });
            if (currentChat && currentChat.chatNameId && token) {
                chatService
                    .getChatsByChatNameId(currentChat.chatNameId, token)
                    .then((data) => {
                        const chatMessages = data.map((chat) => ({
                            role: chat.role,
                            message: chat.message,
                            imageUrl: chat.imageUrl,
                        }));

                        setChatHistory(chatMessages);
                    })
                    .catch((error) => {
                        if (error.message === 'Unauthorized') {
                            console.log(error.message);
                            logout()
                        }
                        console.log(error.message);
                    });
            }
            paymentService.checkPaymentStatus(userId, token, 'chat').then((data) => {
                setIsPaid(data.isPaid)
                setUserDetails(data)
                setIsLoading(true);
            }).catch((error) => {
                if (error.message === 'Unauthorized') {
                    console.log(error.message);
                    logout()
                }
                console.log(error.message);
            });
        }
    }, [currentChat, selectedImage]);

    useEffect(() => {
        let interval;
        if (assistantResponseShown) {
            interval = setInterval(() => {
                setBlinkingActive((prevBlinkingActive) => !prevBlinkingActive);
            }, 30);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [assistantResponseShown]);

    useEffect(() => {
        if (textFieldRef.current && isScrolled) {
            isScrolled = false
            setTimeout(() => {
                let rect = getPosition();
                if (rect) {
                    window.scrollTo({ top: rect.bottom, behavior: 'smooth' });
                }
            }, 1000)

        }
    }, [chatHistory]);

    const getPosition = () => {
        if (textFieldRef.current) {
            const rect = textFieldRef.current.getBoundingClientRect();
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            return {
                top: rect.top + scrollTop,
                bottom: rect.bottom + scrollTop,
            }
        }
    };


    const sendMessage = async () => {
        const openAiResponseRegex = /OpenAI/
        const paymentTermsRegex = /Before you proceed check our payment terms./
        if (userInput && userId && currentChat.chatName && currentChat.chatNameId && token) {
            if (
                selectedImage &&
                userId &&
                userInput &&
                userId &&
                currentChat.chatName &&
                currentChat.chatNameId
            ) {
                const userSendMessage = {
                    role: 'user',
                    message: userInput,
                    imageUrl: URL.createObjectURL(selectedImage),
                };
                setChatHistory((prevHistory) => [...prevHistory, userSendMessage]);
                const base64 = await toBase64(selectedImage);
                const fileType = selectedImage.type
                const fileName = selectedImage.name

                if (base64 && token) {
                    try {
                        const formData = new FormData();

                        formData.append('image', base64);
                        formData.append('content', userInput);
                        formData.append('chatName', currentChat.chatName);
                        formData.append('chatNameId', currentChat.chatNameId);
                        formData.append('userId', userId);
                        formData.append('fileType', fileType);
                        formData.append('fileName', fileName);

                        setHideImage(false);
                        setLoading(true)
                        chatService
                            .sendMultipartData(formData, token)
                            .then((data) => {
                                const assistantMessage = {
                                    role: data.assistantMessage.role,
                                    message: data.assistantMessage.message,
                                };
                                setChatHistory((prevHistory) => [
                                    ...prevHistory,
                                    assistantMessage,
                                ]);
                                setSelectedImage(null);
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = null;
                                }
                                setHideImage(true);
                                setLoading(false);
                                setAssistantResponseShown(true);
                                setTimerActive(true);
                            })
                            .catch((error) => {
                                if (error.message === 'Unauthorized') {
                                    console.log(error.message);
                                    logout()
                                }
                                console.log(error.message);
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = null;
                                }
                                setLoading(false)
                                setSelectedImage(prev => null);
                                setHideImage(true);
                                if (openAiResponseRegex.test(error.message)) {
                                    setErrorApi("We apologize for the inconvenience. It seems that there is an issue with our ChatGPT service. Our team is working to resolve it as soon as possible. Thank you for your patience.")
                                } else if (paymentTermsRegex.test(error.message)) {
                                    setErrorApi(error.message)
                                }
                            });
                    } catch (error) {
                        console.log(error.message);
                    }
                }
            } else {
                const userSendMessage = { role: 'user', message: userInput };
                setChatHistory((prevHistory) => [...prevHistory, userSendMessage]);
                chatService
                    .sendChat({
                        content: userInput,
                        chatName: currentChat.chatName,
                        chatNameId: currentChat.chatNameId,
                        userId,
                    }, token)
                    .then((data) => {
                        const assistantMessage = {
                            role: data.assistantMessage.role,
                            message: data.assistantMessage.message,
                        };
                        const updatedUserDetails = { ...userDetails };
                        updatedUserDetails.chatAssistantMessageLength += assistantMessage.message.split(' ').length;
                        setUserDetails(updatedUserDetails);
                        setChatHistory((prevHistory) => [...prevHistory, assistantMessage]);
                        setAssistantResponseShown(true);
                        setTimerActive(true)

                    })
                    .catch((error) => {
                        if (error.message === 'Unauthorized') {
                            console.log(error.message);
                            logout()
                        }

                        setChatHistory((prevHistory) => prevHistory.slice(0, -1));
                        if (openAiResponseRegex.test(error.message)) {
                            setErrorApi("We apologize for the inconvenience. It seems that there is an issue with our ChatGPT service. Our team is working to resolve it as soon as possible. Thank you for your patience.")
                        } else if (paymentTermsRegex.test(error.message)) {
                            setErrorApi(error.message)
                        }
                        console.log(error.message);
                    });
            }
            setUserInput('');
        }
    };

    const handleCreateChat = () => {
        if (newChatName.trim() && userId && token) {
            let chatName = newChatName.trim();
            if (editingChat) {
                if (editingChat.chatName !== newChatName.trim()) {

                    let data = { chatName, chatNameId: editingChat.chatNameId };
                    chatService
                        .editChatName(data.chatNameId, data, token)
                        .then((data) => {
                            setCurrentChat({
                                chatName: data.chatName,
                                chatNameId: data.chatNameId,
                            });
                            setEditingChat(null);
                            setNewChatName('');
                            setIsCreatingChat(false);
                        })
                        .catch((error) => {
                            if (error.message === 'Unauthorized') {
                                console.log(error.message);
                                logout()
                            }
                            console.log(error.message);
                        });
                }
            } else {
                if (userId && token) {
                    let data = { chatName, userId };
                    chatService
                        .createChatName(data, token)
                        .then((data) => {
                            setCurrentChat({
                                chatName: data.chatName,
                                chatNameId: data.chatNameId,
                            });
                            setNewChatName('');
                            setIsCreatingChat(false);
                            setChatHistory([]);
                        })
                        .catch((error) => {
                            if (error.message === 'Unauthorized') {
                                console.log(error.message);
                                logout()
                            }
                            console.log(error.message);
                        });
                }
            }
        }
    };

    const handleCancelCreateChat = () => {
        setNewChatName('');
        setIsCreatingChat(false);
        setEditingChat(null);
    };

    const handleNewChatClick = () => {
        setIsCreatingChat(true);
    };

    const handleChatItemClick = (chat) => {
        if (chat.chatNameId !== currentChat.chatNameId) {
            setCurrentChat(chat);
            setChatHistory([]);
            isScrolled = true;
        }
    };

    const handleEditChat = (chat) => {
        setEditingChat(chat);
        setNewChatName(chat.chatName);
        setIsCreatingChat(true);
    };

    const handleDeleteChat = (chat) => {
        const shouldDelete = window.confirm(
            `Are you sure you want to delete the chat "${chat.chatName}"?`,
        );
        if (shouldDelete && token) {
            chatService
                .deleteChatsByChatNameId(chat.chatNameId, token)
                .then((data) => {
                    setCurrentChat([]);
                    setChatHistory([]);
                })
                .catch((error) => {
                    if (error.message === 'Unauthorized') {
                        console.log(error.message);
                        logout()
                    }
                    console.log(error.message);
                });
        }
    };

    const logout = () => {
        signout();
        sessionStorage.setItem('pathname', window.location.pathname);
        navigate('/login');
    };

    const removeSelectedImages = () => {
        setSelectedImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const logoutBtn = () => {
        signout();
        navigate('/');
    };

    const handleImageChange = async (e) => {
        const imageFile = e.target.files[0];
        if (!imageFile || !userId || !token) return;
        if (imageFile.name.match(/\.(jpg|jpeg|PNG|gif|JPEG|png|JPG|gif)$/)) {
            if (imageFile.size > 524288) {
                const options = {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                    fileType: imageFile.type,
                    name: imageFile.name,
                }

                try {
                    const compressedFile = await imageCompression(imageFile, options)

                    let compressFile = new File([compressedFile], options.name, { type: imageFile.type })

                    setSelectedImage(prev => compressFile);

                } catch (error) {
                    console.log(error);
                }
            } else {
                setSelectedImage(imageFile)
            }

        }
    };

    if (h3Ref.current) {
        const rect = h3Ref.current.getBoundingClientRect();
        const widthInPixels = rect.width;
        const heightInPixels = rect.height;
        h3Width = widthInPixels;
        h3Height = heightInPixels;
    }


    const handlePay = async () => {
        if (userId && token) {
            try {
                const data = await paymentService.stripeSession(userId, token, 'chat');
                const result = await stripe.redirectToCheckout({
                    sessionId: data.id,
                });

                if (result.error) {
                    console.error(result.error.message);
                }
            } catch (error) {
                if (error.message === 'Unauthorized') {
                    console.log(error.message);
                    logout()
                }
                console.log(error.message);
            }
        }
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorApi(undefined);
    };
    
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="static"
                    elevation={0}
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Toolbar sx={{ flexWrap: 'wrap' }}>
                        <IconButton
                            edge="start"
                            // color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <CameraIcon />
                        </IconButton>

                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            PerkYouUp
                        </Typography>
                        <Button
                            to="/up"
                            component={Link}
                            variant="contained"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Uploads
                        </Button>
                        <Button
                            onClick={logoutBtn}
                            variant="contained"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Sign out
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <div
                style={{
                    display: 'flex',
                    backgroundColor: 'white',
                    minHeight: '80vh',
                }}
            >
                <aside className="left">
                    <Typography variant="h6" align="center" className="drop-title">
                        Chat Names{' '}
                    </Typography>

                    {!isCreatingChat ? (
                        <>
                            <Button variant="outlined" onClick={handleNewChatClick}>
                                + New Chat
                            </Button>
                        </>
                    ) : (
                        <div>
                            <TextField
                                id="outlined-basic"
                                label="Enter chat name"
                                variant="outlined"
                                value={newChatName}
                                onChange={(e) => setNewChatName(e.target.value)}
                            />
                            <Button
                                disabled={newChatName.length > 0 ? false : true}
                                onClick={handleCreateChat}
                                variant="outlined"
                                className="m12"
                            >
                                {editingChat ? 'Edit' : 'Create'}
                            </Button>
                            <Button
                                onClick={handleCancelCreateChat}
                                variant="outlined"
                                className="m12"
                            >
                                Cancel
                            </Button>
                        </div>
                    )}
                    {chats && chats.length > 0 ? (
                        <Paper className="chats-paper">
                            {' '}
                            <MenuList>
                                {chats.map((chat, i) => (
                                    <MenuItem
                                        id={chat.chatNameId}
                                        key={i}
                                        onClick={() => handleChatItemClick(chat)}
                                    >
                                        <ListItemText>
                                            {' '}
                                            {chat.chatName.length > 10
                                                ? chat.chatName.slice(0, 10) + '...'
                                                : chat.chatName}
                                        </ListItemText>
                                        <ListItemIcon>
                                            <IconButton
                                                onClick={(e) => handleEditChat(chat)}
                                                aria-label="edit"
                                                size="small"
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                onClick={(e) => handleDeleteChat(chat)}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </ListItemIcon>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Paper>
                    ) : (
                        ''
                    )}
                    {isLoading && (!isPaid || (isPaid && !((userDetails.chatImages / userDetails.chatPayments) < imageLimitPerPayment))) ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'fixed',
                            bottom: '90px',
                            padding: '5px',
                            transform: 'translateY(40px)',
                            backgroundColor: 'rgba(255, 255, 255, 0.61)',
                            zIndex: '100',
                            borderTop: '1px solid rgb(221, 221, 221)',
                            alignItems: 'center',
                            width: 'var(--left-width)',
                            borderRadius: '5px'
                        }}>
                            <Typography variant="h6" align="left" color="#7bc5c1" gutterBottom>
                                Before you proceed check our payment terms
                            </Typography>
                            <Button variant="contained" onClick={handlePay}>
                                Payments
                            </Button>
                            {userDetails && userDetails.chatPayments === 0 ?
                                <Typography variant="subtitle2" align="left" color="#7bc5c1" gutterBottom>
                                    {userDetails.chatAssistantMessageLength || 0} / {maxFreeWords} words
                                </Typography>
                                : ''}
                        </div>
                        : ''}
                </aside>

                <div
                    style={{
                        flex: 1,
                        paddingTop: '20px',
                        paddingBottom: '20px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Snackbar open={errorApi ? true : false}
                            onClose={handleClose}
                            autoHideDuration={15000}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                            <Alert onClose={handleClose} severity="error">{errorApi}</Alert>
                        </Snackbar>
                        <Typography variant="h5" align="center" className="drop-title">
                            Chat Page
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {chatHistory.length > 0 && (
                                <ul style={{ display: 'flex', flexDirection: 'column' }}>
                                    {chatHistory.map((x, index) => (
                                        <li
                                            key={index}
                                            style={{ listStyleType: 'none', margin: '7px 0', textAlign: 'left', display: 'flex', alignItems: x.imageUrl ? 'flex-start' : 'flex-end', padding: '5px', flexDirection: x.imageUrl ? 'column' : 'row', }}
                                        >
                                            {assistantResponseShown && x.role === 'assistant' && index === chatHistory.length - 1 ? (
                                                (<span >
                                                    Chat : {' '}
                                                    {x.message.split('').map((letter, i) =>
                                                        <DelayedDisplay key={i} letter={letter} i={i} lastIndex={x.message.length - 1} timerActive={setTimerActive} assistantResponseShown={setAssistantResponseShown} />
                                                    )}
                                                    {blinkingActive ? (timerActive ? (
                                                        <div style={{ display: 'inline-block' }}>
                                                            <Paper elevation={3} style={{ width: '13px', height: '18px' }} />
                                                        </div>
                                                    ) : '')
                                                        :
                                                        <div style={{ display: 'inline-block' }}>
                                                            <Paper elevation={3} style={{ width: '13px', height: '18px', backgroundColor: 'gray' }} />
                                                        </div>
                                                    }
                                                </span>)
                                            ) : (
                                                <>
                                                    {x.role === 'assistant'
                                                        ? 'Chat'
                                                        : x.role === 'user'
                                                            ? fullName || x.role
                                                            : x.role}
                                                    {x.imageUrl && (
                                                        <>
                                                            :{' '}
                                                            <Paper
                                                                style={{
                                                                    display: 'inline-block',
                                                                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                                                                    overflow: 'hidden',
                                                                    maxWidth: '100px',
                                                                    alignItems: 'center',
                                                                    margin: '10px 0'
                                                                }}
                                                            >
                                                                <img
                                                                    src={x.imageUrl}
                                                                    alt="User's Img"
                                                                    style={{ width: '100%', height: 'auto', display: 'block' }}
                                                                />
                                                            </Paper>
                                                        </>
                                                    )}{' '}
                                                    :
                                                    {assistantResponseShown && x.role === 'assistant' && index === chatHistory.length - 1 ? (
                                                        <span>
                                                            {x.message.split('').map((letter, i) =>
                                                                <DelayedDisplay key={i} letter={letter} i={i} lastIndex={x.message.length - 1} timerActive={setTimerActive} assistantResponseShown={setAssistantResponseShown} />
                                                            )}
                                                            {blinkingActive ? (timerActive ? (
                                                                <div style={{ display: 'inline-block' }}>
                                                                    <Paper elevation={3} style={{ width: '13px', height: '18px' }} />
                                                                </div>
                                                            ) : '') : (
                                                                <div style={{ display: 'inline-block' }}>
                                                                    <Paper elevation={3} style={{ width: '13px', height: '18px', backgroundColor: 'gray' }} />
                                                                </div>
                                                            )}
                                                        </span>
                                                    ) : x.message}
                                                </>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {currentChat && currentChat.chatName && currentChat.chatNameId ? (
                                <>
                                    <div className="input-wrapper" style={{ '--input-wrapper-flex-direction': isPaid ? 'row' : 'column-reverse' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            {selectedImage && hideImage && (
                                                <>
                                                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                                                        <h3
                                                            onClick={removeSelectedImages}
                                                            style={{
                                                                position: 'absolute',
                                                                marginTop: h3Height ? h3Height / 2 : '10px',
                                                                marginLeft: imageRef && imageRef.current?.width && h3Width ? imageRef.current.width / 2 - (h3Width / 2) : '10%',
                                                                background: 'white',
                                                                borderRadius: '50%',
                                                                padding: '1px',
                                                                cursor: 'pointer',
                                                                zIndex: 1
                                                            }}
                                                            ref={h3Ref}

                                                        >
                                                            X
                                                        </h3>
                                                        <img
                                                            src={URL.createObjectURL(selectedImage)}
                                                            alt="Selected Img"
                                                            style={{
                                                                maxWidth: '100px',
                                                                maxHeight: '100px',
                                                                margin: '10px 0',
                                                                position: 'relative'
                                                            }}
                                                            ref={imageRef}

                                                        />

                                                    </div>
                                                </>
                                            )}

                                            <div id="upload-btn-wrapper">
                                                {isPaid ?
                                                    (
                                                        loading ?
                                                            <CircularIndeterminate />
                                                            : <Button
                                                                component="label"
                                                                variant="contained"
                                                                startIcon={<CloudUploadIcon />}
                                                            >
                                                                <span className="btn-text">Upload Image</span>
                                                                <VisuallyHiddenInput
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="myImage"
                                                                    onChange={handleImageChange}
                                                                    ref={fileInputRef}
                                                                />
                                                            </Button>
                                                    )
                                                    : ''}
                                            </div>
                                        </div>
                                        {isPaid || (!isPaid && maxFreeWords > (userDetails?.chatAssistantMessageLength || 0)) || userDetails.chatPayments > 0 ?
                                            <TextField
                                                ref={textFieldRef}
                                                id="outlined-basic"
                                                label="Start the chat here ..."
                                                variant="outlined"
                                                value={userInput}
                                                onChange={(e) => setUserInput(e.target.value)}
                                                style={{ width: '80%', marginLeft: '5px' }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                color="primary"
                                                                disabled={userInput.length > 0 ? loading : true}
                                                                onClick={sendMessage}
                                                            >
                                                                <Send fontSize="small" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            : ''}
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <Typography
                                        className="response-text"
                                        paragraph
                                        align="center"
                                    >
                                        {' '}
                                        Before asking a question, create a new chat or select an
                                        existing chat.
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ChatPage;

const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};


const DelayedDisplay = ({ letter, i, lastIndex, timerActive, assistantResponseShown }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(true);
            if (i === lastIndex) {
                timerActive(false)
                assistantResponseShown(false)
            }
        }, i * 30);
        return () => clearTimeout(timer);
    }, [i, lastIndex, timerActive, assistantResponseShown]);
    return visible ? letter : '';
};