import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  // const error = useRouteError();
  // console.error(error);

  return (
    <div id="error-page" style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center',
      alignItems: 'center', color: 'white', height: '100vh'
    }}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>

      <p>There's nothing here: 404!</p>
    </div>
  );
}
