import { API_URL } from "../utils/baseUrl";

export async function sendChat(data, token) {
    const response = await fetch(`${API_URL}/chat/send`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }

}



export async function getChatsNamesByUserId(userId, token) {

    const response = await fetch(`${API_URL}/chat-names/chats/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }

}


export async function getChatsByChatNameId(chatNameId, token) {

    const response = await fetch(`${API_URL}/chat/chatNameId/${chatNameId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }

}



export async function createChatName(data, token) {
    const response = await fetch(`${API_URL}/chat-names/create`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }

}

export async function deleteChatsByChatNameId(chatNameId, token) {

    const response = await fetch(`${API_URL}/chat/chatNameId/${chatNameId}`, {
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }

}


export async function editChatName(chatNameId, data, token) {
    const response = await fetch(`${API_URL}/chat-names/chats/${chatNameId}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }

}



export async function sendMultipartData(formData, token) {
    const response = await fetch(`${API_URL}/chat/send-multipart`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: formData,

    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }
}
