
import { API_URL } from "../utils/baseUrl";


export async function register(user) {


    const response = await fetch(`${API_URL}/users/register`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(user)
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }
}

export async function login(user) {


    const response = await fetch(`${API_URL}/users/login`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(user)
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }

}

export async function upload(data, userId, token) {
    const response = await fetch(`${API_URL}/uploads/images/${userId}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        return response.json();
    } else {
        const result = await response.json();
        throw new Error(result.message ? result.message : result);
    }

}



// export async function upload(formData, userId) {

//     const response = await fetch(`${API_URL}/uploads/images/${userId}`, {
//         method: "POST",
//         headers: {},
//         body: formData,
//     });

//     if (response.ok) {
//         return response.json()
//     } else {
//         const result = await response.json();
//         throw new Error(result.message ? result.message : result);
//     }

// }


export async function getAllImagesByUserId(userId, token) {

    const response = await fetch(`${API_URL}/uploads/images/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })

    if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message || errorMessage || 'Get all images failed');
    }
    return response.json();

}