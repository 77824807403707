import React, { useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as paymentService from '../services/paymentService';
import { AuthContext } from '../context/authContext';
import { jwtDecode } from 'jwt-decode';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Toolbar from '@mui/material/Toolbar';

import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import './CheckoutForm.css';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let userId;
const CheckoutForm = ({ onComplete }) => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  if (authState.accessToken) {
    const decode = jwtDecode(authState.accessToken);
    userId = decode.id;
    console.log('User id from context token: ', userId);
  }

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const cardNumberElement = elements?.getElement(CardNumberElement);
    // const cardExpiryElement = elements?.getElement(CardExpiryElement);
    // const cardCvcElement = elements?.getElement(CardCvcElement);
    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement),
      // elements?.getElement(CardExpiryElement),
      // elements?.getElement(CardCvcElement),
    );

    console.log('TOKEN FE:', token);

    if (error) {
      console.log(error.message || error);
    } else {
      await paymentService
        .payment(token, userId)
        .then((data) => {
          console.log('Success:', data);
          onComplete();
          navigate('/home');
        })
        .catch((error) => {
          console.log(error.message || error);
        });
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Toolbar sx={{ flexWrap: 'wrap' }}>
            <IconButton edge="start" aria-label="menu" sx={{ mr: 2 }}>
              <CameraIcon />
            </IconButton>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
              PerkYouUp
            </Typography>
            <nav>
              <Link
                //2b1b17 color
                to="/payments"
                variant="button"
                color="#18453b"
                href="#"
                underline="none"
                sx={{ my: 1, mx: 1.5 }}
              >
                Payments
              </Link>
            </nav>{' '}
            <Button>
              <Link to="/" variant="button" color="#2b1b17" underline="none">
                Sign out
              </Link>
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Box className="faded">
        <div className="div-form-stripe">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            className="m36"
            gutterBottom
          >
            Payment Details
          </Typography>
          <Typography
            variant="h6"
            align="center"
            style={{ padding: '12px' }}
            gutterBottom
          >
            You pay 10$ one time only and you could play with all your photos.
          </Typography>
          <form className="form-stripe" onSubmit={handleSubmit}>
            <CardElement />
            {/* <CardElement className="wrapper" onSubmit={handleSubmit}>
          <CardNumberElement
            options={{
              style: {
                base: inputStyle,
              },
            }}
          />
          <CardExpiryElement
            options={{
              style: {
                base: inputStyle,
              },
            }}
          />
          <CardCvcElement
            options={{
              style: {
                base: inputStyle,
              },
            }}
          />
        </CardElement> */}
            {/* <button className="btn-stripe" type="submit" disabled={!stripe}>
              Pay 10$
            </button> */}
            <Button
              variant="contained"
              to="/home"
              type="submit"
              disabled={!stripe}
              component={Link}
            >
              Pay 10$
            </Button>
          </form>
        </div>
      </Box>
      <div className="armagnac">
        {' '}
        <Box component="footer" sx={{ marginTop: 'auto' }}>
          <Typography variant="h6" align="center" gutterBottom>
            Footer
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            component="p"
          >
            Something here to give the footer a purpose!
          </Typography>
          <Copyright />
        </Box>
      </div>
    </React.Fragment>
  );
};

export default CheckoutForm;
