import * as React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import * as userService from '../services/userService';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';
import { Snackbar, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react'


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/" className="link-color">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const schema = yup.object({
  email: yup.string().required('Please enter your email.').email('Please enter valid email.'),
  firstName: yup.string().required('First Name is required').min(1).max(15).matches(/^(?!\s+$).*(\S{1})/, 'First Name cannot be empty string and must contain at least 1 characters .'),
  lastName: yup.string().required('Last Name is required').min(1).max(15).matches(/^(?!\s+$).*(\S{1})/, 'Last Name cannot be empty string and must contain at least 1 characters .'),
  password: yup.string().required('Password is a required').matches(/^(?!\s+$).*/, 'Password cannot be empty string.'),
  confirmpass: yup.string().matches(/^(?!\s+$).*/, 'Password cannot be empty string.').test('passwords-match', 'Passwords must match', function (value) { return this.parent.password === value }),


}).required();


export default function RegistrationPage() {
  const [errorApi, setErrorApi] = useState('');

  const navigate = useNavigate();
  const authContext = useAuthContext();


  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({

    defaultValues: { email: '', firstName: '', lastName: '', password: '', confirmpass: '' },

    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const registerSubmitHandler = async (data) => {

    await userService
      .register({
        email: data.email.trim(),
        password: data.password.trim(),
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
      })
      .then((token) => {
        authContext.signIn(token.access_token);
        setErrorApi(undefined)
        navigate('/home');
      })
      .catch((error) => {
        setErrorApi(error.message)
        console.log(error.message)
      });
  };
  const handleCloseApi = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorApi(undefined);
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(registerSubmitHandler)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                {...register('firstName')}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                {...register('lastName')}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                {...register('email')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                {...register('password')}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="confirmpass"
                label="Confirm Password"
                type="password"
                id="confirmpass"
                autoComplete="new-password"
                {...register('confirmpass')}
                error={Boolean(errors.confirmpass)}
                helperText={errors.confirmpass?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="secondary"
            disabled={!isDirty || !isValid}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2" className="link-color">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
      <Snackbar open={errorApi ? true : false} autoHideDuration={5000} onClose={handleCloseApi} >
        <Alert onClose={handleCloseApi} severity="error">{errorApi}</Alert>
      </Snackbar>
    </Container>
  );
}
