import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';

export const ProtectedRoute = () => {
  const { authState } = useAuthContext();
  if(!authState.accessToken){
    sessionStorage.setItem('pathname', window.location.pathname);
  }

  return authState.accessToken ? <Outlet /> : <Navigate to="/login" />;
};
// const ProtectedRoute = ({ isAllowed, redirectPath = '/', children }) => {
//   if (!isAllowed) {
//     return <Navigate to={redirectPath} replace />;
//   }

//   return children ? children : <Outlet />;
// };
