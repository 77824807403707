import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import * as userService from '../services/userService';
import { useAuthContext } from '../context/authContext';
import { Snackbar, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link className="link-color" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const schema = yup.object({
  email: yup.string().required('Please enter your email.').email('Please enter valid email.'),
  password: yup.string().required('Please enter your password.').matches(/^(?!\s+$).*/, 'Password cannot be empty string.'),

}).required();

export default function LoginPage() {
  const navigate = useNavigate();
  const authContext = useAuthContext();

  const [errorApi, setErrorApi] = useState('');


  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({

    defaultValues: { email: '', password: '' },
    mode: 'onChange',
    resolver: yupResolver(schema),

  });

  const loginSubmitHandler = async (data) => {

    const pathname = sessionStorage.getItem('pathname');
    await userService
      .login({
        email: data.email.trim(),
        password: data.password.trim(),
      })
      .then((token) => {
        authContext.signIn(token.access_token);
        setErrorApi(undefined);
        if (pathname && pathname !== '/login' && pathname !== '/registration') {
          sessionStorage.removeItem('pathname')
          navigate(pathname);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        setErrorApi(error.message)
        console.log(error.message)
      });
  };

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorApi(undefined);
  };
  return (
    // <div className="fadedBlue">
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit(loginSubmitHandler)} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...register('email')}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register('password')}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isDirty || !isValid}
          >
            {' '}
            Sign In
          </Button>

          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" className="link-color">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/registration" variant="body2" className="link-color">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar open={errorApi ? true : false} onClose={handleClose} autoHideDuration={5000}>
        <Alert onClose={handleClose} severity="error">{errorApi}</Alert>
      </Snackbar>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
    // </div>
  );
}
