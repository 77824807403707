import './App.css';
import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import ErrorPage from './components/ErrorPage';
import Root from './routes/root.js';

import amplifyconfig from './amplifyconfiguration.json';
import AboutPage from './components/AboutPage';
import RegistrationPage from './components/RegistrationPage.js';
import LoginPage from './components/LoginPage.js';

import { ProtectedRoute } from './routes/protectedRoute.js';
import ChatHomePage from './components/ChatHomePage.js';
import StripePayment from './components/StripePayment.js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ChatPage from './components/ChatPage.js';

Amplify.configure({
  ...amplifyconfig,
  Analytics: {
    disabled: true,
  },
  Auth: {
    disabled: true,
  },
});

function App() {
  const [user, setUser] = React.useState(null);
  const [payment, setPayment] = React.useState(null);
  const stripePromise = loadStripe(
    'pk_test_51GiLv8F540OZ8hRRLPioJ3NouApToX5xYVC1YrDPmiShigYYZm8VXBYpv7ERfBPYKzab58CgLOjzopoWCiB432aJ00edTXox9H',
  );

  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <Routes>
          <Route index element={<Root />} />
          <Route path="/up/home" element={<Root />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />

          <Route element={<ProtectedRoute payment={payment} />}>
            {' '}
            <Route path="/payments" element={<StripePayment />}></Route>
          </Route>
          <Route element={<ProtectedRoute user={user} />}>
            <Route path="/up" element={<AboutPage />} />
          </Route>
          <Route path="/chat/home" element={<ChatHomePage />} />

          <Route element={<ProtectedRoute user={user} />}>
            <Route path="/chat" element={<ChatPage />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Elements>
    </div>
  );
}

export default App;
