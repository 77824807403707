import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import feedback from '../assets/feedback.jpg';
import Footer from '../components/Footer';
import ChatSteps from './ChatSteps';
import Lottie from 'react-lottie';
import animationData from '../animations/chatbot.json';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'orange',
}));

const ChatHomePage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <div
          className="hbox"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography
            variant="h6"
            align="left"
            textTransform={'capitalize'}
            color="#7bc5c1"
            paragraph
            gutterBottom
          >
            Take your chat to the next level.{' '}
          </Typography>
          <Button
            to="/chat"
            component={Link}
            variant="text"
            sx={{ my: 1, mx: 1.5 }}
          >
            Login
          </Button>
        </div>
        <Container
          maxWidth="lg"
          sx={{
            marginTop: '50px',
          }}
        >
          {/* <Container sx={{ marginTop: '32px' }}>
            {' '}
            <Grid
              container
              spacing={6}
              sx={{ justifyContent: 'space-between' }}
            >
              {' '}
              <Grid item xs={12} sm={6} marginTop={'12px'}>
                <Typography
                  variant="h5"
                  align="left"
                  textTransform={'capitalize'}
                  color="orange"
                  paragraph
                  gutterBottom
                >
                  Need answers...{' '}
                </Typography>
                <Typography
                  variant="h6"
                  align="left"
                  textTransform={'capitalize'}
                  color="orange"
                  paragraph
                  textAlign={'center'}
                  gutterBottom
                >
                  Then start asking the right questions.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageList cols={1}>
                  {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                      <img
                        srcSet={`${feedback}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`${feedback}?w=164&h=164&fit=crop&auto=format`}
                        alt={item.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
          </Container> */}
          <Container
            sx={{ display: 'flex', alignItems: 'end', margin: '1em auto' }}
          >
            <Lottie options={defaultOptions} height={400} width={460} />
          </Container>
          <Container>
            <Typography
              variant="h6"
              align="left"
              textTransform={'capitalize'}
              color="orange"
              paragraph
              textAlign={'center'}
              gutterBottom
            >
              In our chat you can find help for:
            </Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              sx={{ justifyContent: 'center' }}
            >
              <Item>Making homework</Item>
              <Item>Writing an article</Item>
              <Item>A document overview</Item>
              <Item>Brainstorming</Item>
              <Item>Tweets</Item>
              <Typography
                variant="h6"
                align="left"
                color="orange"
                paragraph
                textAlign={'center'}
                gutterBottom
              >
                ... and many more
              </Typography>
            </Stack>
          </Container>
          <Container sx={{ marginTop: '3em' }}>
            {' '}
            <Link to="/chat">
              <Button
                type="submit"
                // fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: 'orange',
                }}
              >
                Let's play chats
              </Button>
            </Link>
          </Container>
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default ChatHomePage;

const itemData = [
  {
    img: 'https://unsplash.com/photos/black-and-silver-slr-camera-mS1nlYbq1kA',
    title: 'Vintage camera light painted',
    author: '@rshunev',
  },
];
<Container>
  {' '}
  <Grid container spacing={6} sx={{ justifyContent: 'space-between' }}>
    {' '}
    <Grid item xs={12} sm={6}>
      {/* <Typography
                  variant="h6"
                  align="left"
                  textTransform={'capitalize'}
                  color="#7bc5c1"
                  paragraph
                  gutterBottom
                >
                  Take your chat to the next level.{' '}
                </Typography>
                <Typography
                  component="h2"
                  variant="h2"
                  align="right"
                  color="orange"
                  gutterBottom
                >
                  Chat with AI
                </Typography> */}
      {/* 
                <Typography
                  variant="h5"
                  align="left"
                  textTransform={'capitalize'}
                  color="#7bc5c1"
                  paragraph
                  textAlign={'center'}
                  gutterBottom
                >
                  Don't mince words, satisfy your curiosity
                </Typography> */}
    </Grid>
  </Grid>
</Container>;
