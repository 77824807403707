import * as React from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Steps from '../components/Stepper';
import Typography from '@mui/material/Typography';
import socialMedia from '../assets/socialMedia.jpg';
import feedback from '../assets/feedback.jpg';
import Footer from '../components/Footer';

export default function Root({ user }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            marginTop: '50px',
          }}
        >
          <Grid container spacing={6} sx={{ justifyContent: 'space-around' }}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h6"
                align="left"
                textTransform={'capitalize'}
                color="#7bc5c1"
                paragraph
                gutterBottom
              >
                Take your socials to the next level.{' '}
              </Typography>
              <Typography
                component="h2"
                variant="h2"
                align="right"
                color="orange"
                gutterBottom
              >
                PerkYouUp
              </Typography>

              <Typography
                variant="h5"
                align="left"
                textTransform={'capitalize'}
                color="#7bc5c1"
                paragraph
                textAlign={'center'}
                gutterBottom
              >
                Increase click rates, increase subscriptions.
              </Typography>
              <Typography
                variant="h5"
                align="left"
                textTransform={'capitalize'}
                color="#7bc5c1"
                paragraph
                textAlign={'center'}
                gutterBottom
              >
                Show yourself in the best light possible.
              </Typography>
              <Typography
                variant="h5"
                align="left"
                textTransform={'capitalize'}
                color="#7bc5c1"
                paragraph
                textAlign={'center'}
                gutterBottom
              >
                Market yourself up.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ImageList cols={1}>
                {itemData.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      srcSet={`${socialMedia}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${socialMedia}?w=164&h=164&fit=crop&auto=format`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          </Grid>
        </Container>
        <Box></Box>
        <Container sx={{ marginTop: '40px' }}>
          <Typography variant="h6" color="#7bc5c1" component="div" gutterBottom>
            How does it work?
          </Typography>
          <Steps />
        </Container>
        <Container sx={{ marginTop: '40px' }}>
          <Typography variant="h6" color="#7bc5c1" component="div" gutterBottom>
            You can upload up to 10 photos and see what feedback you'll get
          </Typography>
          <Link to="/up">
            <Button
              type="submit"
              // fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#fcae1e;',
                marginTop: '18px',
              }}
            >
              Let's play uploads
            </Button>
          </Link>
        </Container>

        <Footer />
      </Box>
    </>
  );
}

const itemData = [
  {
    img: 'https://unsplash.com/photos/black-and-silver-slr-camera-mS1nlYbq1kA',
    title: 'Vintage camera light painted',
    author: '@rshunev',
  },
];
