import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CameraIcon from '@mui/icons-material/PhotoCamera';

import Footer from '../components/Footer';
import UploadAndDisplayImage from './Upload';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useState } from 'react';
import { AuthContext } from '../context/authContext';
import * as paymentService from '../services/paymentService';
import * as userService from '../services/userService';
import { useStripe } from '@stripe/react-stripe-js';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Snackbar, Alert } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
export const CircularIndeterminate = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
};

let userId = undefined;
let token = undefined;
//https://chat.google.com/dm/kV9YW0AAAAE/XY9IV747sAE/XY9IV747sAE?cls=10
export default function AboutPage() {
  const [expandedId, setExpandedId] = React.useState({});

  const handleExpandClick = (i) => {
    setExpandedId((prevExpanded) => ({
      ...prevExpanded,
      [i]: !prevExpanded[i],
    }));
  };

  const navigate = useNavigate();
  const { authState, signout } = useContext(AuthContext);
  const [isPaid, setIsPaid] = useState(false);
  const [images, setImages] = useState([]);
  const [uploadImage, setUploadImage] = useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [userDetails, setUserDetails] = useState();
  const [errorApi, setErrorApi] = useState('');

  if (authState.accessToken) {
    const decode = jwtDecode(authState.accessToken);
    userId = decode.id;
    token = authState.accessToken;
  }

  const stripe = useStripe();
  useEffect(() => {
    if (userId && token) {
      Promise.all([
        paymentService.checkPaymentStatus(userId, token, 'up'),
        userService.getAllImagesByUserId(userId, token),
      ])
        .then(([paymentData, imagesData]) => {
          setIsLoading(true);
          setIsPaid(paymentData.isPaid);
          setImages(imagesData);
          setUserDetails(paymentData);
        })
        .catch((error) => {
          if (error.message === 'Unauthorized') {
            console.log(error.message);
            logout();
          }
          console.log(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [uploadImage]);

  let combinedImages = [
    ...images.map((image) => ({
      img: image.imageUrl,
      title: image.response ? image.response : 'image',
    })),
    ...itemData,
  ];

  const handlePay = async () => {
    if (userId && token) {
      try {
        const data = await paymentService.stripeSession(userId, token, 'up');
        const result = await stripe.redirectToCheckout({
          sessionId: data.id,
        });

        if (result.error) {
          console.error(result.error.message);
        }
      } catch (error) {
        if (error.message === 'Unauthorized') {
          console.log(error.message);
          logout();
        }
        console.log(error.message);
      }
    }
  };

  const logout = () => {
    signout();
    sessionStorage.setItem('pathname', window.location.pathname);
    navigate('/login');
  };

  const handleImageUpload = (newImageData) => {
    setUploadImage(newImageData);
  };

  const logoutBtn = () => {
    signout();
    navigate('/');
  };
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorApi(undefined);
  };
 
  if (isLoading) return <p>Loading....</p>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Toolbar sx={{ flexWrap: 'wrap' }}>
            <IconButton
              edge="start"
              // color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <CameraIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              PerkYouUp
            </Typography>
            {/* <Button
              to="/chat"
              component={Link}
              variant="contained"
              sx={{ my: 1, mx: 1.5 }}
            >
              Chat
            </Button> */}
            <Button
              onClick={logoutBtn}
              variant="contained"
              sx={{ my: 1, mx: 1.5 }}
            >
              Sign out
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <div className="vbox">
        <Snackbar open={errorApi ? true : false}
          onClose={handleClose}
          autoHideDuration={15000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <Alert onClose={handleClose} severity="error">{errorApi}</Alert>
        </Snackbar>
        {!isPaid && userDetails.upImages > 0 ? (
          <Box sx={{ flexDirection: 'row', margin: '25px' }}>
            {' '}
            <Typography variant="h6" align="left" color="#7bc5c1" gutterBottom>
              Before you proceed check our payment terms
            </Typography>
            <Button variant="contained" onClick={handlePay}>
              Payments
            </Button>
          </Box>
        ) : (
          ''
        )}
        <Box sx={{ margin: '50px' }}>
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <UploadAndDisplayImage
                  onImageUpload={handleImageUpload}
                  isPaid={isPaid}
                  images={images}
                  userDetails={userDetails}
                  errorApi={setErrorApi}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="drop-container">
                  <Typography
                    variant="h6"
                    align="center"
                    className="drop-title"
                  >
                    Here you'll see what our magician says, but it may take a
                    while
                  </Typography>

                  <Typography className="response-text" paragraph align="left">
                    {isLoading ? (
                      <CircularIndeterminate />
                    ) : uploadImage ? (
                      uploadImage.response
                    ) : (
                      ''
                    )}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent={'space-between'}
        >
          {combinedImages && combinedImages.length > 0
            ? combinedImages.map((item, i) => (
                <Grid item xs={12} sm={6} md={2} key={i}>
                  <Card
                    key={item.img}
                    sx={{
                      margin: 2,
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="194"
                      image={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                      alt={item.title ? item.title.slice(0, 10) : 'image'}
                      title={item.title ? item.title : 'image'}
                    />

                    <CardActions disableSpacing>
                      <Typography>Click to read the answer</Typography>
                      <ExpandMore
                        expand={expandedId[i]}
                        onClick={() => handleExpandClick(i)}
                        aria-expanded={expandedId[i]}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expandedId[i]} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography paragraph>{item.title}</Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              ))
            : null}
        </Grid>
      </div>
      <Footer />
    </Box>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },

  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },

  // {
  //   img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
  //   title: 'Honey',
  //   author: '@arwinneil',
  // },
];
