import React, { useState, useEffect, useRef } from 'react';
import * as userService from '../services/userService';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useContext } from 'react';
import { AuthContext } from '../context/authContext';
import { jwtDecode } from 'jwt-decode';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { CircularIndeterminate } from './AboutPage'

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

let userId = undefined;
let token = undefined;
const imageLimitPerPayment = 10;
const UploadAndDisplayImage = ({ onImageUpload, isPaid, images, userDetails, errorApi }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isElementVisible, setIsElementVisible] = useState(false);
  const [loading, setLoading] = useState(false)

  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const { authState, signout } = useContext(AuthContext);

  useEffect(() => {
    setIsElementVisible(
      (isPaid && ((userDetails.upImages / userDetails.upPayments) < imageLimitPerPayment)) || (!isPaid && images.length < 1),
    );
  }, [isPaid, images]);

  if (authState.accessToken) {
    const decode = jwtDecode(authState.accessToken);
    userId = decode.id;
    token = authState.accessToken;
  }




  const handleImageChange = async (e) => {
    const imageFile = e.target.files[0];

    if (!imageFile || !userId || !token) return;
    if (imageFile.name.match(/\.(jpg|jpeg|PNG|gif|JPEG|png|JPG|gif)$/)) {
      if (imageFile.size > 524288) {
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          fileType: imageFile.type,
          name: imageFile.name,
          alwaysKeepResolution: true
        }

        try {
          const compressedFile = await imageCompression(imageFile, options)

          let compressFile = new File([compressedFile], options.name, { type: imageFile.type })

          setSelectedImage(prev => compressFile);

        } catch (err) {
          console.log(err);
        }
      } else {
        setSelectedImage(imageFile)
      }
    }
  };

  const sendImage = async () => {

    if (!selectedImage || !userId || !token) return;

    const base64 = await toBase64(selectedImage);
    const fileType = selectedImage.type
    const fileName = selectedImage.name

    if (base64) {
      try {
        const body = {
          originalname: fileName,
          mimetype: fileType,
          base64: base64,
        }
        setLoading(true)
        const data = await userService.upload(body, userId, token);
        setLoading(false)
        setSelectedImage(null)
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        onImageUpload(data);
      } catch (error) {
        if (error.message === 'Unauthorized') {
          console.log(error.message);
          logout()
        }

        const openAiResponseRegex = /OpenAI/
        const paymentTermsRegex = /Before you proceed check our payment terms./
        console.log(error.message);
        if (openAiResponseRegex.test(error.message)) {
          errorApi("We apologize for the inconvenience. It seems that there is an issue with our ChatGPT service. Our team is working to resolve it as soon as possible. Thank you for your patience.")
        } else if (paymentTermsRegex.test(error.message)) {
          errorApi(error.message)
        }
        setLoading(false)
      }
    }
  }

  const logout = () => {
    signout();
    navigate('/login');
  };

  return (
    <div className="drop-container">
      {isElementVisible ? (
        <>
          <h1 className="drop-title">Upload and Display Image</h1>
          {selectedImage ? (
            <>
              <div style={{ maxHeight: '220px' }}>
                <img
                  alt="not found"
                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  src={URL.createObjectURL(selectedImage)}
                />
              </div>
              <div style={{ display: 'flex', width: '70%', flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between' }}>
                {loading ?
                  <CircularIndeterminate /> :
                  <Button
                    sx={{ margin: '5px' }}
                    variant="contained"
                    onClick={sendImage}
                  >
                    Upload
                  </Button>
                }
                <Button
                  sx={{ margin: '5px' }}
                  variant="contained"
                  className="remove-btn"
                  onClick={() => setSelectedImage(null)}
                >
                  Remove
                </Button>
              </div>
            </>
          ) : (
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                name="myImage"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
              />
            </Button>
          )}
        </>
      ) : ((userDetails.upImages / userDetails.upPayments) === imageLimitPerPayment) ? (
        <Typography variant="h6" align="center" color="#7bc5c1" gutterBottom>
          {imageLimitPerPayment} images is the maximum to upload
        </Typography>
      ) : images.length >= 1 ? (
        <Typography variant="h6" align="center" color="#7bc5c1" gutterBottom>
          Before you proceed check our payment terms
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
};
export default UploadAndDisplayImage;

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
