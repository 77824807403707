import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();
const initialAuthState = {
  accessToken: '',
  payment: '',
};
const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(() => {
    const storedAccessToken = sessionStorage.getItem('accessToken') || '';
    return { accessToken: storedAccessToken };
  });

  const signIn = (token) => {
    const updatedAuthState = { ...authState, accessToken: token };
    setAuthState(updatedAuthState);
    sessionStorage.setItem('accessToken', token);
  };
  const pay = (payment) => {
    const updatedPaymentState = { ...authState, payment: payment };
    setAuthState(updatedPaymentState);
  };

  const signout = () => {
    setAuthState(initialAuthState);
    sessionStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ authState, signIn, pay, signout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return authContext;
};

export { AuthProvider, useAuthContext };
