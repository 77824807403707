import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51GiLv8F540OZ8hRRLPioJ3NouApToX5xYVC1YrDPmiShigYYZm8VXBYpv7ERfBPYKzab58CgLOjzopoWCiB432aJ00edTXox9H');

const StripePayment = () => {
  const [paymentComplete, setPaymentComplete] = useState(false);
  const navigate = useNavigate()
  const handlePaymentComplete = () => {
    setPaymentComplete(true);
    navigate('/home')
  };



  return (
    <div>
      {!paymentComplete ?
        <Elements stripe={stripePromise}>
          <CheckoutForm onComplete={handlePaymentComplete} />
        </Elements>
        : ''}
    </div>
  );
};

export default StripePayment;
