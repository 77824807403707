import { API_URL } from "../utils/baseUrl";


export async function payment(token, userId) {

    const response = await fetch(`${API_URL}/payments/process`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, userId }),
    })

    if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message || errorMessage || 'Payment failed');
    }

    return response.json();
}


export async function stripeSession(userId, token, paymentType) {

    const response = await fetch(`${API_URL}/payments/create-checkout-session`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ userId, paymentType }),
    });

    if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message || errorMessage || 'Payment failed');
    }

    return response.json();
}



export async function checkPaymentStatus(userId, token, paymentType) {

    const response = await fetch(`${API_URL}/payments/check-payment/${userId}?paymentType=${paymentType}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })

    if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message || errorMessage || 'Payment failed');
    }
    return response.json();

}